import { useEffect, useRef, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Wrapper } from './styles'
import {
    Form,
    logoutReqSchema,
    logoutResSchema,
    ResSchema,
    validationSchema,
} from './utils/constants'
import FormView from './components/Form'
import Back from '../../components/Back'
import Button from '../../components/Button'
import SpacerY from '../../components/Spacer/Y'
import Layout from '../../layouts/Layout'
import { api, url } from '../../utils/constants'
import useMutation from '../../hooks/useMutation'
import { storage } from '../../utils'

export default function Setting() {
    const user = storage.getUser()
    const navigate = useNavigate()
    const [isDisabled, setIsDisabled] = useState<boolean>(true)
    const [initialValues, setInitialValues] = useState<Form | null>(null)
    const formikRef = useRef<FormikProps<Form> | null>(null)
    const { postAction } = useMutation<Form, ResSchema>({
        apiURL: `${api.setting}/update`,
    })
    const { postAction: logoutAction } = useMutation<
        logoutReqSchema,
        logoutResSchema
    >({
        apiURL: api.logout,
    })

    useEffect(() => {
        if (!user) return

        setInitialValues({
            ['user_id']: user.user_id,
        })
    }, [])

    const handleSubmit = async (value: Form) => {
        const res = await postAction(value)

        if (res.error) return
        if (!res.data) return
        const user = res.data.resources.user
        storage.setUser(user)
        toast.success(res.data.message)
        navigate(`/${user.user_id}`)
    }

    const handleLogout = async () => {
        const res = await logoutAction()
        if (res.error) return
        if (!res.data) return
        storage.clearStorage()
        toast.success(res.data.message)
        navigate(url.top)
    }

    return (
        <Layout
            headerTitle="設定"
            headerLeftEle={
                <Back
                    width={65}
                    height={25}
                    text={'キャンセル'}
                    onClick={() => navigate(-1)}
                />
            }
            headerRightEle={
                <Button
                    type="button"
                    width={66}
                    height={25}
                    paddingY={4}
                    paddingX={8}
                    fontSize={10}
                    fontWeight={700}
                    disabled={isDisabled}
                    onClick={() => formikRef.current?.submitForm()}
                >
                    保存する
                </Button>
            }
            isVisibleBottomNav={false}
            isHeaderBorderBottom={true}
        >
            <Wrapper>
                <SpacerY height={24} />
                {initialValues && (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        innerRef={formikRef}
                        onSubmit={handleSubmit}
                    >
                        {(props) => (
                            <FormView
                                user={user!}
                                setIsDisabled={setIsDisabled}
                                {...props}
                            />
                        )}
                    </Formik>
                )}
                <SpacerY height={40} />
                <Button
                    width={339}
                    height={40}
                    btnType="secondary"
                    fontWeight={700}
                >
                    お問い合わせ
                </Button>
                <SpacerY height={24} />
                <Button
                    width={339}
                    height={40}
                    btnType="error"
                    fontWeight={700}
                    onClick={handleLogout}
                >
                    ログアウト
                </Button>
            </Wrapper>
        </Layout>
    )
}
